import React, { Component } from "react";
import { Typography } from "@material-ui/core";

class Clients extends Component {
  render() {
    const clients = [
      {
        id: 1,
        logo: "/imgs/clients/flashchat_logo.png",
        alt: "Bespoke software tailored to your needs.",
      },
      {
        id: 2,
        logo: "/imgs/clients/IMTF_Logo.jpg",
        alt: "Bespoke software tailored to your needs.",
      },
      {
        id: 3,
        logo: "/imgs/clients/resolute-logo.jpg",
        alt: "Bespoke software tailored to your needs.",
      }
    ];

    return (
      <section id="clients" className="clients-container">
        <Typography className="paper-title" variant="h4" component="h4">Trusted by</Typography>
        {clients.map(client => (
          <img className="client-logo" alt={client.alt} key={client.id} src={client.logo} />
        ))}
      <div className="overlay-mask"></div>
      </section>
    );
  }
}
export default Clients;
