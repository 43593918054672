import React, { Component } from "react";
import {
  Link,
  Button,
  Grid,
  Paper,
  Typography,
  Icon,
  Container
} from "@material-ui/core";

class Services extends Component {
  render() {
    const serviceTexts = [
      {
        id: 1,
        icon: "",
        title: "Bespoke software tailored to your needs.",
        text:
          "Our experienced team will break down the needs of your company and together we will design the future."
      },
      {
        id: 2,
        icon: "",
        title: "Award-winning web design and branding.",
        text:
          "We work with the top UI/UX designers in order to provide the best solution for your product."
      },
      {
        id: 3,
        icon: "",
        title: "Software consulting",
        text:
          "Software consultancy including requirement analysis, design and architecture, prototyping and implementation."
      },
      {
        id: 4,
        icon: "",
        title: "Legacy Software Modernization",
        text:
          "Our company offers a service to update or replace your outdated legacy systems with cutting-edge new technologies and efficient custom software systems. We will bring back the efficiency of your business and get it to the new level to outrun your competition."
      }
    ];

    return (
      <section id="services" className="services-container">
        <Container>
          <Typography variant="h2" component="h2" className="section-title">
            What We Do
          </Typography>
          <Grid item xs={12}>
            <Grid container justify="center">
              {serviceTexts.map(service => (
                <Grid key={service.id} item md={6} xs={12}>
                  <Paper className="paper">
                    {service.icon && <Icon className="fa fa-plus-circle" />}
                    <Typography
                      className="paper-title"
                      variant="h3"
                      component="h3"
                    >
                      {service.title}
                    </Typography>
                    <Typography component="h4" variant="h4" className="paper-subtitle">
                      {service.text}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>
      </section>
    );
  }
}
export default Services;
