import React, { useEffect, useRef, useState } from "react";
import { Link, Container, Typography } from "@material-ui/core";
import { Parallax } from "react-scroll-parallax";
import { useMediaQuery } from 'react-responsive';

function AppHeader() {
  const navRef = useRef(null);
  const [navClass, setNavClass] = useState("navbar");
  const vidRef = useRef(null)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setNavClass(navClass + " active-nav");
      } else {
        setNavClass("navbar");
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  });

  const menuItems = [
    { link: "services", title: "Services", class: "menu-item" },
    // { link: "case-studies", title: "Case Studies", class: "menu-item" },
    // { link: "clients", title: "Clients", class: "menu-item" },
    { link: "values", title: "Values", class: "menu-item" },
    { link: "contact", title: "CONTACT US", class: "menu-action" }
  ];
  
  let navlInks = menuItems.map(item => (
    <Link
      key={item.link}
      href={'#' + item.link}
      className={item.class}
    >
      {item.title}
    </Link>
  ));

  const headerBack = isMobileDevice ? (<img id="hero" alt="header" src="/imgs/hero-mobi.jpg" />) :
    <video autoPlay muted loop width="100%" id="header-video" ref={vidRef}>
      <source  src="../videos/video.mp4" type="video/mp4"/>
    </video>

  const playVideo = () => {
    vidRef.current.play();
  }

  useEffect(() => {
    playVideo()
  }, [])

  const ParallaxImage = () => (
    <Parallax
      className="custom-class"
      offsetYMin={-100}
      offsetYMax={100}
      tagOuter="figure"
    >
      <img id="hero" src="/imgs/hero.jpg" />
    </Parallax>
  );
  return (
    <header>
      <div className={navClass} ref={navRef}>
        <Container>
          <nav className="header_topnav_right">{navlInks}</nav>
          <a href="/">
            <nav className="header_topnav_left">
              <img
                alt="we are fabbrik logo"
                className="App-logo"
                src="/imgs/logo-white.png"
              />
              <img
                alt="we are fabbrik logo"
                className="app-logo-scroll"
                src="/imgs/logo-green.png"
              />
            </nav>
          </a>
        </Container>
      </div>
      <section>
        {/* <img id="hero" src="/imgs/hero.jpg" /> */}
        {/* {ParallaxImage} */}
        <div className="video-container">{headerBack}</div>
        <Container>
          <div className="headline-container">
            <Typography component="h1" variant="h1" className="headline">
              We Are A Custom Software Development Company
            </Typography>
            <Typography component="h3" variant="h3" className="headline">
              Turning Your Great Ideas into Successful Web Solutions
            </Typography>
            <Typography component="h4" variant="h4" className="subtitle">
              We have the experience and the expertise to build your custom web
              & mobile solutions. IT consulting, software development and
              testing is what we do.
            </Typography>
            {/* <Button
                variant="outlined"
                color="primary"
                className="header-button"
                href="#services"
              >
                Learn more
              </Button> */}
          </div>
        </Container>
      </section>
    </header>
  );
}

export default AppHeader;
