import React, { Component } from "react";
import { Grid, Container, Link } from "@material-ui/core";

class Footer extends Component {
  construnctor(props) {}

  render() {
    const preventDefault = event => event.preventDefault();
    return (
      <footer>
        <Container>
          <Grid container justify="center">
            <Grid md={4} xs={12} item className="footer-item">
              <a href="/" className="footer-logo">
                <img className="App-logo" src="/imgs/logo-white.png" />
              </a>

              <span className="marginTop25">info@wearefabbrik.com</span>
              <span>Sepapaja tn 6, Tallinn</span>
              <span>Estonia</span>
            </Grid>
            <Grid md={4} xs={12} item></Grid>
            <Grid md={4} xs={12} item></Grid>
          </Grid>
          <div className="trademark">
            © 2020 by WeAreFabbrik OÜ. All rights reserved
          </div>
          {/* <div className="privacy-policy">
            <Link href="/policy" color="inherit">
              {"Privacy Policy"}
            </Link>
          </div> */}
        </Container>
      </footer>
    );
  }
}
export default Footer;
