import React from 'react';

function UnderConstruction(){

    return(
        <div className="under-construction">
            <img className="big-logo" src="/imgs/logo.png" />
            <h4>Coming soon...</h4>
            {/* <video width="1080" height="1080" autoPlay>
                <source src="/videos/animated-logo.mp4" type="video/mp4"/>
            
                Your browser does not support the video tag.
            </video> */}
        </div>
    );
}
export default UnderConstruction;